import { IThunderboltEntry } from '@wix/editor-elements-types';

const thenReturnDefault = (componentModule: any) => {
  return {
    component: componentModule.default,
  };
};

const entry: IThunderboltEntry = {
  loadComponent: hostAPI => {
    hostAPI.registerComponent(
      'StylableHorizontalMenu',
      () => {
        return import(
          './viewer/uiTypes/StylableHorizontalMenu_wrap_column' /* webpackChunkName: "StylableHorizontalMenu_wrap_column" */
        ).then(thenReturnDefault);
      },
      'wrap_column',
    );

    hostAPI.registerComponent(
      'StylableHorizontalMenu',
      () => {
        return import(
          './viewer/uiTypes/StylableHorizontalMenu_wrap_flyout' /* webpackChunkName: "StylableHorizontalMenu_wrap_flyout" */
        ).then(thenReturnDefault);
      },
      'wrap_flyout',
    );

    hostAPI.registerComponent(
      'StylableHorizontalMenu',
      () => {
        return import(
          './viewer/uiTypes/StylableHorizontalMenu_scroll_column' /* webpackChunkName: "StylableHorizontalMenu_scroll_column" */
        ).then(thenReturnDefault);
      },
      'scroll_column',
    );

    hostAPI.registerComponent(
      'StylableHorizontalMenu',
      () => {
        return import(
          './viewer/uiTypes/StylableHorizontalMenu_scroll_flyout' /* webpackChunkName: "StylableHorizontalMenu_scroll_flyout" */
        ).then(thenReturnDefault);
      },
      'scroll_flyout',
    );
  },
};

export default entry;
